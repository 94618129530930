@import '../../../styles/base.scss';

.orderItemBox{
    margin-bottom: 0.5rem;
    // box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
    padding: 0.5rem;
    border-bottom: 1px solid gainsboro;
    img{
        height: 7rem;
        margin: auto;
        display: block;
        width: 100%;
        @include mobile(){
            width: 100%;
        }
    }
    p{
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 0.4rem;
    }
    .orderedItemName{
        text-transform: capitalize;
    }
}